import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import InnerHero from "../components/Shared/innerHero"
import PdfModal from "../components/Carreer/pdf-modal"
import { Button } from "reactstrap"

function CarreersDetail({ data }) {
  const post = data.strapiJobPosts
  const brd = [{ title: "Careers", to: "/careers" }]

  const [politics1, setPolitics1] = React.useState(false)
  const [politics2, setPolitics2] = React.useState(false)
  const [politics3, setPolitics3] = React.useState(false)
  const [showDeclaration, setShowDeclaration] = React.useState(false)
  const [showDisclosure, setShowDisclosure] = React.useState(false)
  const [showDataTransfer, setShowDataTransfer] = React.useState(false)
  return (
    <Layout>
      <Seo title="Carreers Detail" />
      <InnerHero
        title={post.title}
        description={`${post.location} - ${post.employment_type}`}
        breadcrumb={brd}
      />
      <PdfModal
        pdfPath={"/pdf/acik_riza_metni.pdf"}
        showModal={showDeclaration}
        setShowModal={setShowDeclaration}
      />
      <PdfModal
        pdfPath={"/pdf/aydinlatma_metni.pdf"}
        showModal={showDisclosure}
        setShowModal={setShowDisclosure}
      />
      <PdfModal
        pdfPath={"/pdf/yurt_disi_aktarim.pdf"}
        showModal={showDataTransfer}
        setShowModal={setShowDataTransfer}
      />

      {/* <section
        className="inner-hero bg_img"
        data-background="/bg/inner-hero.jpg"
      >
        <div className="shape position-absolute">
          <img src="/elements/inner-hero/shape.png" alt="no surrender" />
        </div>
        <div className="el-1 position-absolute">
          <img src="/elements/inner-hero/el-1.png" alt="no surrender" />
        </div>
        <div className="el-2 position-absolute">
          <img src="/elements/inner-hero/el-2.png" alt="no surrender" />
        </div>
        <div className="el-3 position-absolute">
          <img src="/elements/inner-hero/el-3.png" alt="no surrender" />
        </div>
        <div className="el-4 position-absolute">
          <img src="/elements/inner-hero/el-4.png" alt="no surrender" />
        </div>
        <div className="el-5 position-absolute">
          <img src="/elements/inner-hero/el-5.png" alt="no surrender" />
        </div>
        <div className="el-6 position-absolute">
          <img src="/elements/inner-hero/el-6.png" alt="no surrender" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="page-title">
                {post.title}
              </h2>
              <p className="page-description">{post.location} - {post.employment_type}</p>
              <ul className="page-list">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/careers">Careers</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section> */}
      <section className="career-details pt-60 pb-120">
        <div className="container">
          <div className="row justify-content-center pb-5">
            <div className="col-lg-10 mt-50">
              <div className="career-details__content">
                <h3 className="career-details__sub-title pb-3 border-bottom">
                  Job Description
                </h3>
                <div dangerouslySetInnerHTML={{ __html: post.content }} />
                {/* <p>
                  We are seeking a talented 2D Animator who will create
                  realistic and highly stylized animations. Join our team if you
                  want to create game animations that reach millions of people
                  around the world.
                </p>
                <p>
                  Concepts, characters, game assets, and backgrounds are
                  designed by our Illustrators. As a 2D Animator, you will be
                  working closely with Artists and Engineers to bring these
                  items to life in a 2-Dimension game environment.
                </p> */}
                <h3 className="mt-50 career-details__sub-title pb-3 border-bottom">
                  What you’ll be doing
                </h3>
                <div dangerouslySetInnerHTML={{ __html: post.what_do }} />
                {/* <ul>
                  <li>
                    <p>
                      Creating 2D animations for stylized characters and items
                    </p>
                  </li>
                  <li>
                    <p>
                      Create animations and effects for UI elements, including
                      icons, pop-ups, buttons, backgrounds, and menus
                    </p>
                  </li>
                  <li>
                    <p>
                      Collaborating with the engineering team in order to create
                      2D Particle FX in Unity
                    </p>
                  </li>
                  <li>
                    <p>Editing animated assets in Unity and Spine</p>
                  </li>
                  <li>
                    <p>
                      Working together with the team to envision and iterate
                      animations
                    </p>
                  </li>
                  <li>
                    <p>Growing and fostering a user-oriented design culture</p>
                  </li>
                </ul> */}
                <h3 className="mt-50 career-details__sub-title pb-3 border-bottom">
                  Desired skills and experiences
                </h3>
                <div dangerouslySetInnerHTML={{ __html: post.skills }} />
                {/* <ul>
                  <li>
                    <p>Experience with bone and spritesheet animation style</p>
                  </li>
                  <li>
                    <p>
                      Familiarity with graphic design, fine arts, illustration,
                      or visual arts
                    </p>
                  </li>
                  <li>
                    <p>
                      Good knowledge of digital visualization tools (i.e. Spine,
                      After Effects, Photoshop, Illustrator)
                    </p>
                  </li>
                  <li>
                    <p>
                      Basic skills necessary to make modifications of game
                      assets
                    </p>
                  </li>
                  <li>
                    <p>
                      Knowledge of processes, pipelines, and best practices for
                      2D animation production
                    </p>
                  </li>
                  <li>
                    <p>
                      A keen eye for color harmonies, lighting, perspective, and
                      materials
                    </p>
                  </li>
                  <li>
                    <p>
                      Process and methods for optimizing assets for mobile
                      devices
                    </p>
                  </li>
                  <li>
                    <p>A portfolio demonstrating your skills</p>
                  </li>
                </ul> */}
                <div className="mt-50">
                  <div
                    dangerouslySetInnerHTML={{ __html: post.last_content }}
                  />
                  {/* <p>
                    At NoSurrender, we’re passionate about making engaging
                    mobile games. We have a company culture that promotes
                    cooperation and professional growth. We’ll give you
                    everything you need so you can focus on what you do best. If
                    you want to join one of the most dynamic companies in the
                    industry while getting your work in front of millions around
                    the world, apply now!
                  </p> */}
                  <div className="d-flex flex-column mt-50">
                    <div className="mr-3 d-flex">
                      <input
                        type="checkbox"
                        id="agree1"
                        value={politics1}
                        onChange={() => setPolitics1(!politics1)}
                      />
                      <label htmlFor="agree1" className="ml-3 mb-0">
                        I agree to the{" "}
                        <Button
                          onClick={() => setShowDeclaration(!showDeclaration)}
                          className="text-primary bg-transparent p-0 border-0"
                        >
                          Declaration of Explicit Consent
                        </Button>
                      </label>
                    </div>
                    <div className="mr-3 d-flex">
                      <input
                        type="checkbox"
                        id="agree2"
                        value={politics2}
                        onChange={() => setPolitics2(!politics2)}
                      />
                      <label htmlFor="agree2" className="ml-3 mb-0">
                        I agree to the{" "}
                        <Button
                          onClick={() => setShowDisclosure(!showDisclosure)}
                          className="text-primary bg-transparent p-0 border-0"
                        >
                          Clarification Text
                        </Button>
                      </label>
                    </div>
                    <div className="d-flex">
                      <input
                        type="checkbox"
                        id="agree3"
                        value={politics3}
                        onChange={() => setPolitics3(!politics3)}
                      />
                      <label htmlFor="agree3" className="ml-3 mb-0">
                        I agree to the{" "}
                        <Button
                          onClick={() => setShowDataTransfer(!showDataTransfer)}
                          className="text-primary bg-transparent p-0 border-0"
                        >
                          Declaration form for data transfer
                        </Button>
                      </label>
                    </div>
                  </div>
                  <a
                    className="apply-btn cmn-btn mt-3 disabled"
                    href="mailto:hr@nosurrender.studio"
                    data-wow-duration="0.5s"
                    data-wow-delay="0.7s"
                    aria-disabled={!(politics1 && politics2 && politics3)}
                  >
                    Apply
                  </a>
                  <PdfModal />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default CarreersDetail

export const query = graphql`
  query($slug: String!) {
    strapiJobPosts(slug: { eq: $slug }) {
      what_do
      title
      slug
      skills
      location
      last_content
      employment_type
      content
      post_end_at
      post_start_at
    }
  }
`
