import React, { useState } from "react"
import { Modal, ModalBody } from "reactstrap"

const PdfModal = ({ pdfPath, showModal, setShowModal }) => {
  return (
    <div>
      <Modal
        className="modal--pdf"
        isOpen={showModal}
        toggle={() => setShowModal(!showModal)}
      >
        <ModalBody>
          <embed src={pdfPath} frameborder="0" width="100%" height="100%" />
        </ModalBody>
      </Modal>
    </div>
  )
}

export default PdfModal
